<template>
  <div class="column is-half is-offset-one-quarter">

    <CabezoteA h2="Registro" title="Verifique todos los campos antes de enviar el formulario por favor." />

    <div class="columns">
      <div class="column">
        <!--FORMULARIO DE INGRESO -->
        <form class="box rounded-box" @submit.prevent="register">
          <div>
            <h3 class="title">Registro</h3>
            <h6 class="subtitle">Verifique todos los campos antes de enviar el formulario por favor.</h6>
          </div>
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input
                v-model="email"
                class="input"
                type="email"
                placeholder="Email"
                required
                :disabled="estadoBoton"
              />
            </div>
          </div>

          <!-- Campo de contraseña con validación y mensaje de error -->
          <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-right">
              <input
                class="input"
                :type="showPassword ? 'text' : 'password'"
                placeholder="********"
                v-model="password"
                required
                :disabled="estadoBoton"
              />
              <span class="icon is-small is-right">
                <i
                  @click="togglePasswordVisibility('password')"
                  :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                ></i>
              </span>
            </div>
            <p v-if="passwordRulesError" class="help is-danger">
              La contraseña debe tener al menos una mayúscula, un número y
              mínimo 6 caracteres.
            </p>
          </div>

          <div class="field">
            <label class="label">Confirmar Password</label>
            <div class="control has-icons-right">
              <input
                class="input"
                :type="showPasswordConfirmation ? 'text' : 'password'"
                placeholder="********"
                v-model="passwordConfirmation"
                required
                :disabled="estadoBoton"
              />
              <span class="icon is-small is-right">
                <i
                  @click="togglePasswordVisibility('passwordConfirmation')"
                  :class="
                    showPasswordConfirmation ? 'fas fa-eye-slash' : 'fas fa-eye'
                  "
                ></i>
              </span>
            </div>
            <p v-if="passwordMismatch" class="help is-danger">
              Las contraseñas no coinciden.
            </p>
          </div>

          <div class="field">
            <label class="label">Número Celular</label>
            <div class="field has-addons">
              <!-- Campo de selección de país -->
              <div class="control has-icons-left">
                <div class="select">
                  <select v-model="selectedCountry">
                    <option
                      v-for="country in countries"
                      :key="country.indicativo"
                      :value="country.indicativo"
                      :disabled="estadoBoton"
                    >
                      {{ country.bandera }} (+{{ country.indicativo }})
                    </option>
                  </select>
                </div>
                <div class="icon is-small is-left">
                  <i class="fas fa-globe"></i>
                </div>
              </div>

              <!-- Campo de número de celular -->
              <div class="control is-expanded">
                <input
                  class="input"
                  type="phone"
                  placeholder="Número Celular"
                  v-model="phone"
                  required
                  inputmode="numeric"
                  pattern="[0-9]*"
                  onwheel="return false;"
                  :disabled="estadoBoton"
                  @input="limitarLongitud"
                />
              </div>
            </div>
          </div>

          <!--
          <CapC tituloBoton="Resgistrarse"/>
           -->

          <button
            :disabled="
              !(
                selectedCountry != '' &&
                phone >= 9 &&
                passwordConfirmation === password &&
                password != '' &&
                passwordConfirmation != ''
              ) || estadoBoton
            "
            class="button is-warning is-rounded is-fullwidth mt-5"
          >
            Registrarme
          </button>

          <!-- RESPUESTA -->
          <span v-if="respuesta" class="respuesta">
            <progress class="progress is-small is-warning" max="100"></progress>
            <br />
            {{ respuesta }}
          </span>
        </form>

        <!-- BOTONERA -->
        <div class="column buttons are-small is-flex is-justify-center">
          <button
          class="button is-warning is-outlined is-rounded is-fullwidth-mobile"
            @click="goToLogin"
          >
            Login
          </button>
        </div>
      </div>
    </div>

    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </div>
</template>



<script >
//import CapC from "@/components/ReCap.vue";
import Whatsapp from "@/components/Whatsapp.vue";
import { RegisterFB } from "@/config/registro";
import CabezoteA from "@/views/CabezoteA.vue";

export default {
  name: "RegisterFrom",
  //CapC
  components: { Whatsapp, CabezoteA },

  data() {
    return {
      respuesta: null,
      email: "",
      password: "",
      passwordConfirmation: "",
      passwordWithIcon: "",
      showPassword: false,
      selectedCountry: "1",
      countries: [
        {
          nombre: "Estados Unidos",
          indicativo: "1",
          bandera: "🇺🇸",
          select: true,
        },
        { nombre: "Colombia", indicativo: "57", bandera: "🇨🇴", select: false },
      ],
      estadoBoton: false,
      showPasswordConfirmation: false,
      phone: "",
    };
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: "Login" });
    },

    async register() {
      this.estadoBoton = true;
      this.respuesta = "Procesando solicitud...";
      // Validar si las contraseñas coinciden antes de registrar
      if (this.password !== this.passwordConfirmation) {
        //console.log("Las contraseñas no coinciden");
        return;
      }

      // selectedCountry
      // Extraer el número del país seleccionado
      //const numeroPais = this.selectedCountry.match(/\+(\d+)/)[1];
      //console.log("Número del país seleccionado:", this.selectedCountry);

      // celular

      //const numeroTotal = numeroPais + this.celular;

      const numeroTotal = this.selectedCountry + this.phone;

      //console.log(numeroTotal);

      const resultLogin = await RegisterFB(
        this.email,
        this.password,
        numeroTotal
      );

      //console.log(resultLogin);

      if (resultLogin[0]) {
        this.respuesta =
          "Registro exitoso, por favor ve a tu correo y confirma tu registro para continuar.";
        setTimeout(() => {
          this.estadoBoton = false;
          this.respuesta = null;
          this.email = "";
          this.password = "";
          this.passwordConfirmation = "";
          this.phone = "";
          this.selectedCountry = "1";

          this.$router.push({ name: "Login" });
        }, 3500); // 3000 milisegundos = 3 segundos
      } else {
        if (resultLogin[1] == "auth/email-already-in-use") {
          this.respuesta = "El correo ya está registrado, intente de nuevo.";
        } else {
          this.respuesta = "Los datos no son correctos, intente de nuevo.";
        }

        setTimeout(() => {
          this.estadoBoton = false;
          this.respuesta = null;
          this.email = "";
          this.password = "";
          this.passwordConfirmation = "";
          this.phone = "";
          this.selectedCountry = "1";
        }, 3000); // 3000 milisegundos = 3 segundos
      }

      //this.respuesta = null;
    },

    togglePasswordVisibility(target) {
      if (target === "password") {
        this.showPassword = !this.showPassword;
      } else if (target === "passwordConfirmation") {
        this.showPasswordConfirmation = !this.showPasswordConfirmation;
      }
    },

    togglePasswordConfirmationVisibility() {
      this.showPasswordConfirmation = !this.showPasswordConfirmation;
    },

    limitarLongitud() {
      if (this.phone.length > 9) {
        this.phone = this.phone.slice(0, 10);
      }
    },
  },

  computed: {
    passwordMismatch() {
      return this.password !== this.passwordConfirmation;
    },

    passwordRulesError() {
      if(this.password == ""){return;}

      const uppercaseRegex = /[A-Z]/;
      const numberRegex = /[0-9]/;
      return (
        this.password.length < 6 ||
        !uppercaseRegex.test(this.password) ||
        !numberRegex.test(this.password)
      );
    },
  },
};
</script>

<style scoped>
/* Estilo para ocultar las flechas en los campos numéricos */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
/* END Estilo para ocultar las flechas en los campos numéricos */
.select-country {
  width: 90px; /* Ajusta el ancho según tu preferencia */
}
/* Estilos para la respuesta */
.respuesta {
  display: block;
  text-align: center;
  margin-top: 10px;
  color: hsl(171, 100%, 41%); /* Cambia el color a tu preferencia */
}

.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.control.has-icons-right .icon.is-right {
  z-index: 2;
  pointer-events: all;
  top: -10%;
}

.control input.input {
  z-index: 1;
  position: relative;
}


.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
</style> 