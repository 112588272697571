import { db, collection, addDoc, query, where, getDocs, doc, updateDoc, orderBy, arrayUnion, setDoc } from "@/utils/firebaseConfig.js";

//Para Calculadora
export async function consultaUsuarioInversionCalFB(usuario) {
    try {
        let inversiones = [];
        let posicion = 0;
        //desc 
        const q = query(collection(db, "solicitudes"), where("usuario", "==", usuario), orderBy("fechaRegistro", "asc"));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {

            //console.log(doc.id, " => ", doc.data());
            posicion++;
            inversiones.push({ id: doc.id, datos: doc.data(), posicion: posicion })
        });


        if (inversiones.length > 0) {
            return [true];
        } else {
            return [false];
        }


    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error("Error en el inicio de sesión:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}


//Para Calculadora
export async function registroInversionFB(json) {
    try {
        // Agregar la propiedad fechaRegistro al objeto json
        json.fechaRegistro = new Date();
        json.estadoCliente = true;
        json.estadoAdmin = false;

        const mesActual = new Date().toLocaleString('default', { month: 'long' }); // Obtiene el nombre del mes actual
        const porcentaje = json.porcentaje * json.investment; // Calcula el porcentaje

        const rentabilidadMensual = [
            {
                mes: mesActual,
                dias: 0,
                rentabilidad: porcentaje,
                rentabilidadTotal: json.investmentProv, //json.investment,
                porcentajeGanancia: json.porcentaje,
                pago: false,
                fechaRegistro: new Date(),
                tipoMovimiento: "Inversion",
                estadoAdmin: false,
            },
        ];

        json.rentabilidadMensual = rentabilidadMensual;

        // Asegura que rentabilidadMensual sea un array
        if (!Array.isArray(json.rentabilidadMensual)) {
            json.rentabilidadMensual = [json.rentabilidadMensual];
        }

        const docRef = await addDoc(collection(db, "solicitudes"), json);

        const docId = docRef.id; // Recuperar el ID automático

        // Agregar el ID al documento
        const updatedData = {
            ...json, // Copia todos los datos originales
            id: docId, // Agrega el ID como propiedad "id"
        };

        // Construir la referencia al documento con el ID correcto
        const updatedDocRef = doc(collection(db, "solicitudes"), docId);

        // Actualiza el documento con la propiedad "id"
        await setDoc(updatedDocRef, updatedData);

        return [true, updatedDocRef];

    } catch (error) {
        console.error(error);
        return [false, error];
    }

}

//Para Transacciones
export async function consultaUsuarioInversionFB(usuario) {
    try {
        let inversiones = [];
        let posicion = 0;
        //desc 
        const q = query(collection(db, "solicitudes"), where("usuario", "==", usuario), orderBy("fechaRegistro", "asc"));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {

            //console.log(doc.id, " => ", doc.data());
            posicion++;
            inversiones.push({ id: doc.id, datos: doc.data(), posicion: posicion })
        });

        // Ordenar inversiones por fechaRegistro en orden descendente
        inversiones.sort((a, b) => b.datos.fechaRegistro - a.datos.fechaRegistro);

        return [true, inversiones];

    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error("Error en el inicio de sesión:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}


export async function updateInversionFB(json) {
    try {

        //console.log(json);
        const fechaRegistro = new Date();
        //retiroAmount - selectedOption
        const documento = json.id;
        const monto = json.retiroAmount;
        const tipoS = json.selectedOption;

        const Ref = doc(db, "solicitudes", documento);

        await updateDoc(Ref, {
            retiro: {
                Monto: monto,
                Opcion: tipoS,
                "fecha-Solicitud": fechaRegistro,
                estadoUsuario: true,
                estadoAdmin: false,
            }
        });

        return [true];

    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error("Error en el inicio de sesión:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}

//Registro movimiento desde Transacciones Inversor
export async function solicitudesinversorFB(json) {
    try {

        //console.log(json);

        const fechaActual = new Date();
        const opciones = { month: 'long' };
        const nombreMesActual = new Intl.DateTimeFormat('es-ES', opciones).format(fechaActual);


        const registro = json.id;
        // Agregar la propiedad fechaRegistro al objeto json
        json.fechaRegistro = fechaActual;
        json.mes = nombreMesActual;
        json.estadoCliente = true;
        json.estadoAdmin = false;

        const q = query(collection(db, "solicitudes"), where("id", "==", registro));

        // Obtiene los documentos que cumplen con el criterio de búsqueda
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
            const inversionDocRef = doc.ref;

            await updateDoc(inversionDocRef, {
                rentabilidadMensual: arrayUnion(...[json]),
            });
        });



        return [true];

    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error("Error en el inicio de sesión:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}
