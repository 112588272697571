<template>
  <div class="column is-half is-offset-one-quarter">
    <CabezoteA h2="Restablecer Contraseña" title="e-Virtual Investments" />

    <div class="columns">
      <div class="column">
        <!--FORMULARIO DE INGRESO -->
        <form class="box rounded-box" @submit.prevent="recuperarCuenta">

          <div>
            <h3 class="title">Recuperar Contraseña</h3>
            <h6 class="subtitle">Digite su correo de registro</h6>
          </div>

          <div class="field">
            <label class="label">Correo Electrónico</label>
            <div class="field has-addons">
              <input
                class="input"
                type="email"
                v-model="email"
                placeholder="Email"
                required
                :disabled="estadoBoton"
              />
            </div>
          </div>

          <button
          class="button is-warning is-rounded is-fullwidth mt-5"
            type="submit"
            :disabled="estadoBoton || email == '' || email.length < 9"
          >
            Recuperar Contraseña
          </button>

          <span v-if="estadoBoton" class="respuesta mt-5">
            <progress class="progress is-small is-warning" max="100"></progress>
            <br />
            {{ respuesta }}
          </span>
        </form>

        <!-- BOTONERA -->
        <div class="column buttons are-small is-flex is-justify-center">
          <button class="button is-warning is-outlined is-rounded is-fullwidth-mobile reduce-margin-bottom-mobile" @click="goToLogin">Login</button>
        </div>
      </div>
    </div>
    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </div>
</template>
    
<script>
import CabezoteA from "@/views/CabezoteA.vue";
import Whatsapp from "@/components/Whatsapp.vue";
import { recuperarContrasenaFB } from "@/config/registro";

export default {
  name: "LoginForm",

  components: { Whatsapp, CabezoteA },

  data() {
    return {
      email: "",
      estadoBoton: false,
      respuesta: "",
    };
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: "Login" });
    },

    async recuperarCuenta() {
      this.estadoBoton = true;
      this.respuesta = "Generando la petición";

      const resultLogin = await recuperarContrasenaFB(this.email);
      //console.log(resultLogin);

      if (resultLogin[0]) {
        this.respuesta = "Se ha enviado al correo la solicitud";

        setTimeout(() => {
          this.respuesta = null;
          // Llama a la acción del store para iniciar sesión y almacenar los datos
          this.estadoBoton = false;
          this.$router.push({ name: "Login" });
        }, 3000); // 3000 milisegundos = 3 segundos
      } else {
        this.respuesta = "Se ha enviado al correo la petición";

        setTimeout(() => {
          this.respuesta = null;
          // Llama a la acción del store para iniciar sesión y almacenar los datos
          this.estadoBoton = false;
          this.$router.push({ name: "Login" });
        }, 3000); // 3000 milisegundos = 3 segundos
      }
    },
  },
};
</script>

<style scoped>
.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
</style>
    