import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createVuetify } from 'vuetify';
import { aliases, fa } from 'vuetify/iconsets/fa'
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'vuetify/dist/vuetify.css';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import '@fortawesome/fontawesome-free/css/all.css';

import 'bulma/css/bulma.min.css';

const app = createApp(App);

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
  lang: {
    // Reemplaza el texto "Items per page" por "Elementos por página"
    current: 'es',
    locales: {
      es: {
        dataIterator: {
          pageText: 'Página:',
          noResultsText: 'No se encontraron elementos',
          perPageText: 'Elementos por página:', // Aquí realizamos el cambio
        },
        dataTable: {
          rowsPerPageText: 'Elementos por página:', // Esto es para la paginación
        },
      },
    },
  },
});

app.use(store);
app.use(router);
app.use(vuetify);

// Configura el plugin de reCAPTCHA con tus claves
app.use(VueReCaptcha, {
  siteKey: '6LezGpAnAAAAAOuCgJxJRySWQVKyYBB0tREJAZ8E',  // Reemplaza 'x' con tu clave real
});

app.mount('#app');