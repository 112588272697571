// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { onAuthStateChanged, getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, updatePhoneNumber, PhoneAuthProvider, updatePassword, getASecureRandomPassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { getFirestore, collection, addDoc, query, where, getDocs, docs, updateDoc, doc, orderBy, Timestamp, arrayUnion, getDoc, setDoc, increment, decrement } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, list } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB8zohkmU3n8CW2jEuNFakthx9gDc21FPI",
  authDomain: "bolsa-f.firebaseapp.com",
  projectId: "bolsa-f",
  storageBucket: "bolsa-f.appspot.com",
  messagingSenderId: "614160310967",
  appId: "1:614160310967:web:54c826325c05ee90d7e332",
  measurementId: "G-WVGM83FLTN"
};


// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);
const analytics = getAnalytics(firebase);
const auth = getAuth(firebase);
const storage = getStorage(firebase);

//console.log(auth);


export { firebase, db, collection, addDoc, query, where, getDocs, getDoc, updateDoc, docs, doc, storage, ref, uploadBytesResumable, getDownloadURL, analytics, auth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, updatePhoneNumber, EmailAuthProvider, PhoneAuthProvider, updatePassword, getASecureRandomPassword, reauthenticateWithCredential, list, orderBy, Timestamp, arrayUnion, setDoc, increment, decrement };