import { db, doc, updateDoc, Timestamp } from "@/utils/firebaseConfig.js";

export async function updateInversionFB(json) {
    try {

        //console.log(json);

        // Crea una nueva instancia de Date para obtener la fecha y hora actual
        const fechaActual = new Date();
        const timestamp = Timestamp.fromDate(fechaActual);


        // Convierte la fecha actual en una marca de tiempo Unix (timestamp)
        json.datos.fechaRegistro = timestamp;

        let id = json.datos.id;

        // Referencia al documento que deseas actualizar
        const inversionRef = doc(db, 'solicitudes', id);

        // Actualiza el documento con los nuevos datos
        await updateDoc(inversionRef, json.datos);

        return [true];

    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error("Error en el inicio de sesión:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}

