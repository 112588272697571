<template>
  <v-app>
    <v-main 
      :style="{
        backgroundImage: 'url(' + require('@/assets/fondo-02.png') + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh', // Establecer una altura mínima igual a la altura de la pantalla
        height: 'auto', // Permitir que la altura se ajuste automáticamente al contenido
        margin: 0
      }"
    >
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
