<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item">
        <img
          :src="require('@/assets/logo-horizontal-02.png')"
          width="200"
          style="max-height: 120px"
        />
      </a>

      <!-- Mostrar el botón de menú en resoluciones pequeñas -->
      <a
        @click="goToOut"
        class="navbar-burger btn-salir-mobile d-md-none"
        aria-label="close"
        aria-expanded="false"
        >X</a
      >
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a
              @click="goToOut"
              class="button is-light is-small"
              style="
                color: #000000;
                background-color: #f8d07e;
                border-radius: 100%;
                width: 25px;
                height: 25px;
              "
              >X</a
            >
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "DashboardBar01",

  data() {
    return {};
  },

  methods: {
    goToOut() {
      const auth = getAuth();
      signOut(auth)
        .then(async () => {
          await signOut(auth);
          // Sign-out successful.
          this.$router.push({ name: "Login" });
        })
        .catch(async (error) => {
          await signOut(auth);
          // An error happened.
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.logo-image {
  width: 100px; /* Ajusta el tamaño del logo según tus preferencias */
  height: auto;
}

.logo-container {
  display: flex;
  align-items: center; /* Centra verticalmente */
}

.logo-image {
  width: 100px; /* Ajusta el ancho según tus preferencias */
  height: auto; /* Ajusta la altura automáticamente según el ancho */
  margin-right: 20px; /* Espacio entre la imagen y el texto */
}

.navbar {
  background-color: transparent !important; /* !important asegura que esta regla sobrescriba cualquier otro estilo */
  box-shadow: none; /* Opcional: quita cualquier sombra del navbar si la tienes */
}

.btn-salir-mobile {
  color: #000000;
  background-color: #f8d07e;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  text-align: center;
  display: flex;
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra horizontalmente */
  margin-top: 25px;
  font-size: small;
}
</style>