<template>
  <div class="column is-half is-offset-one-quarter">

    <CabezoteA h2="Recuperar Cuenta" title="e-Virtual Investments" />

  
    <div class="columns">
      <div class="column">
        <!--FORMULARIO DE INGRESO -->
        <form class="box rounded-box" @submit.prevent="recuperarCuenta">

          <div>
            <h3 class="title">Recuperar Cuenta</h3>
            <h6 class="subtitle">Digite su número de teléfono registrado</h6>
          </div>

          <div class="field">
            <label class="label">Número Celular</label>
            <div class="field has-addons">
              <!-- Campo de selección de país -->
              <div class="control has-icons-left">
                <div class="select">
                  <select v-model="selectedCountry">
                    <option
                      v-for="country in countries"
                      :key="country.indicativo"
                      :value="country.indicativo"
                      :disabled="estadoBoton"
                    >
                      {{ country.bandera }} (+{{ country.indicativo }})
                    </option>
                  </select>
                </div>
                <div class="icon is-small is-left">
                  <i class="fas fa-globe"></i>
                </div>
              </div>

              <!-- Campo de número de celular -->
              <div class="control is-expanded">
                <input
                  class="input"
                  type="phone"
                  placeholder="Número Celular"
                  v-model="phone"
                  required
                  inputmode="numeric"
                  pattern="[0-9]*"
                  onwheel="return false;"
                  :disabled="estadoBoton"
                  @input="limitarLongitud"
                />
              </div>
            </div>

            <button
            class="button is-warning is-rounded is-fullwidth mt-5"
              type="submit"
              :disabled="estadoBoton || phone == '' || phone.length < 10 "
            >
              Recuperar Cuenta
            </button>

            <span v-if="estadoBoton" class="respuesta mt-5">
              <progress
                class="progress is-small is-warning"
                max="100"
              ></progress>
              <br />
              {{ respuesta }}
            </span>

            <span v-if="partialEmail">
              <br /><br />
              Se ha enviado al correo:
              <!-- Mostrar el correo electrónico de manera parcial -->
              {{ partialEmail }}
            </span>
          </div>
        </form>

        <!-- BOTONERA -->
        <div class="column buttons are-small is-flex is-justify-center">
          <button
          class="button is-warning is-outlined is-rounded is-fullwidth-mobile reduce-margin-bottom-mobile "
            @click="goToLogin"
          >
            Login
          </button>
        </div>
      </div>
    </div>

    

    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </div>
</template>
    
<script>
import Whatsapp from "@/components/Whatsapp.vue";
import { recuperarContrasenaPorTelefono } from "@/config/registro";
import CabezoteA from "@/views/CabezoteA.vue";

export default {
  name: "RecuperarCuentaForm",

  components: { Whatsapp, CabezoteA },

  data() {
    return {
      email: null,
      phone: "",
      selectedCountry: "1", // Establece el valor de selectedCountry en "1"
      countries: [
        {
          nombre: "Estados Unidos",
          indicativo: "1",
          bandera: "🇺🇸",
          select: true,
        },
        { nombre: "Colombia", indicativo: "57", bandera: "🇨🇴", select: false },
      ],
      estadoBoton: false,
      respuesta: "",
    };
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "Login" });
    },

    async recuperarCuenta() {
      this.estadoBoton = true;
      this.respuesta = "Procesando la solicitud";

      let numero = this.selectedCountry + this.phone;

      const resultLogin = await recuperarContrasenaPorTelefono(numero);

      //console.log(resultLogin);
      if (resultLogin[0]) {
        let correoTrans = this.correoTran(resultLogin[2]);
        this.respuesta =
          "Se ha envia al correo de registro las instrucciones." + correoTrans;

        setTimeout(() => {
          this.respuesta = null;
          // Llama a la acción del store para iniciar sesión y almacenar los datos
          this.estadoBoton = false;
          this.$router.push({ name: "Login" });
        }, 3000); // 3000 milisegundos = 3 segundos
      } else {
        this.respuesta = "El proceso ha fallado contacte al Administrador.";

        setTimeout(() => {
          this.respuesta = null;
          // Llama a la acción del store para iniciar sesión y almacenar los datos
          this.estadoBoton = false;
          this.$router.push({ name: "Login" });
        }, 3000); // 3000 milisegundos = 3 segundos
      }
    },

    correoTran(correo) {
      if (correo) {
        const firstThree = correo.substring(0, 3);
        const atIndex = correo.indexOf("@");
        if (atIndex !== -1) {
          return `${firstThree}...${correo.substring(atIndex)}`;
        } else {
          return `${firstThree}...`;
        }
      }
      return "";
    },

    limitarLongitud() {
      if (this.phone.length > 9) {
        this.phone = this.phone.slice(0, 10);
      }
    },

  },
  computed: {
    partialEmail() {
      if (this.email) {
        const firstThree = this.email.substring(0, 3);
        const atIndex = this.email.indexOf("@");
        if (atIndex !== -1) {
          return `${firstThree}...${this.email.substring(atIndex)}`;
        } else {
          return `${firstThree}...`;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped>
.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
</style>
    