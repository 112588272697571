import { db, collection, query, getDocs, where, orderBy, updateDoc, arrayUnion, getDoc, increment, doc } from "@/utils/firebaseConfig.js";
//const { getDaysInMonth } = require("date-fns");

export async function agregaArrayRentabilidades(json) {
    try {
        //console.log(json);
        const id = json.datos.id;

        const q = query(collection(db, "solicitudes"), where("id", "==", id));

        // Obtiene los documentos que cumplen con el criterio de búsqueda
        const querySnapshot = await getDocs(q);

        // Itera a través de los documentos y actualiza la propiedad "rentabilidadMensual"
        querySnapshot.forEach(async (doc) => {
            const inversionDocRef = doc.ref;
            await updateDoc(inversionDocRef, {
                rentabilidadMensual: json.datos.rentabilidadMensual,
                rentabilidadTotal: json.datos.rentabilidadTotal
            });

            //console.log(inversionDocRef);
        });

        return [true];
    } catch (error) {
        console.log(error);
        return [false];
    }
}

//Transacciones Administrator PANEL PRINCIPAL
export async function actualizaRentabilidadMensualFB(arrayLlega) {
    try {
        //console.log(arrayLlega);
        const fechaActual = new Date();
        const opciones = { month: 'long' }; // 'long' significa que obtendrás el nombre completo del mes
        const nombreMes = fechaActual.toLocaleString('es-ES', opciones);

        const id = arrayLlega[1];
        const propiedad = arrayLlega[0];//estadoAdmin <-- Esta la propuedad a modificar
        const posicionArray = arrayLlega[2];
        const nuevoValor = arrayLlega[3];
        const porcentaje = arrayLlega[4] || 0; // Si es undefined o null, se considera como 0
        const tipoMovimiento = arrayLlega[5] //movimiento
        const rentabilidad = arrayLlega[6] //rentabilidad


        //Hace consulta
        const qURM = query(collection(db, "solicitudes"), where("id", "==", id));
        const querySnapshot = await getDocs(qURM);

        for (const doc of querySnapshot.docs) {
            const inversionDocRef = doc.ref;
            const docData = (await getDoc(inversionDocRef)).data();

            //Modificamos la propiedad dentro del array RENTABILIDADMENSUAL
            if (Array.isArray(docData.rentabilidadMensual)) {
                if (posicionArray < docData.rentabilidadMensual.length) {
                    docData.rentabilidadMensual[posicionArray][propiedad] = nuevoValor;
                    docData.rentabilidadMensual[posicionArray].porcentajeGanancia = porcentaje;

                    // Se actualiza la fecha para poder calcular la rentabilidad
                    // en el caso contrario no se actualiza fecha
                    if (tipoMovimiento === "Adicionar Capital" || tipoMovimiento === "Inversion") {
                        docData.rentabilidadMensual[posicionArray].fechaRegistro = fechaActual;
                        docData.rentabilidadMensual[posicionArray].mes = nombreMes;
                    }

                }
            }

            //actualiza la Propiedad RENTABILIDADMENSUAL
            await updateDoc(inversionDocRef, {
                rentabilidadMensual: docData.rentabilidadMensual
            });

            //acctualiza los que está fuera de rentabilidadMensual DATOS GENERALES
            if (tipoMovimiento === "Adicionar Capital" || tipoMovimiento === "Inversion") {
                await updateDoc(inversionDocRef, {
                    porcentaje: porcentaje,
                    investment: increment(rentabilidad),
                });
            }

            //En caso que sea Retirar Rendimientos se debe restar rentabilidadTotal
            if (tipoMovimiento === "Retirar Rendimientos") {
                await updateDoc(inversionDocRef, {
                    //porcentaje: porcentaje,
                    rentabilidadTotal: increment(rentabilidad),
                });
            }

            if (tipoMovimiento === "Retirar Capital") {
                await updateDoc(inversionDocRef, {
                    //porcentaje: porcentaje,
                    investment: increment(rentabilidad),
                });
            }

        }

        return [true];

    } catch (error) {
        console.log(error);
    }
}

//USUARIOS.VUE
export async function agregaPosicionArrayRentabilidades(json) {
    try {
        //console.log(json);
        const nombresDeMeses = [
            "enero", "febrero", "marzo", "abril", "mayo", "junio",
            "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
        ];
        //const anoActual = new Date().getFullYear();
        const fechaActual = new Date();
        const id = json.datos.id;

        let acumuladoRentailidadTotal = json.datos.rentabilidadTotal;

        if (!isNaN(acumuladoRentailidadTotal)) {
            acumuladoRentailidadTotal = parseFloat(acumuladoRentailidadTotal);
        }

        const porcentajeGanancia = json.datos.porcentaje / 100; // Convertimos el porcentaje a decimal
        const rentabilidad = porcentajeGanancia * json.datos.investment;

        let valorNumericoPG = parseFloat(rentabilidad.toFixed(2));

        let acumuladoTotalRNew = 0;


        const nuevasPosiciones = json.mesesFaltantes.map((mes) => {
            //const mesIndex = nombresDeMeses.indexOf(mes);
            const mesIndex = nombresDeMeses.indexOf(mes.mesCalculado);
            const diasEnElMes = mes.dias;

            if (diasEnElMes < (30 || 31)) {
                let valorDia = valorNumericoPG / 30;
                let recalculaRentabilidad = valorDia * diasEnElMes;
                valorNumericoPG = recalculaRentabilidad;
            }

            acumuladoTotalRNew += valorNumericoPG;

            if (mesIndex !== -1) {
                return {
                    mes: mes.mesCalculado,
                    dias: diasEnElMes, // Aquí se establece la cantidad de días
                    rentabilidad: rentabilidad,
                    rentabilidadTotal: valorNumericoPG,
                    porcentajeGanancia: json.datos.porcentaje,
                    pago: false,
                    fechaRegistro: fechaActual,
                    tipoMovimiento: "Rendimiento",
                    estadoAdmin: true,
                };
            } else {
                //console.log("Mes no válido.");
                return null; // Otra opción es manejar el mes no válido de la manera que necesites
            }
        });

        if (nuevasPosiciones) {
            const q = query(collection(db, "solicitudes"), where("id", "==", id));

            // Obtiene los documentos que cumplen con el criterio de búsqueda
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (doc) => {
                const inversionDocRef = doc.ref;
                await updateDoc(inversionDocRef, {
                    rentabilidadMensual: arrayUnion(...nuevasPosiciones),
                });


                await updateDoc(inversionDocRef, {
                    rentabilidadTotal: increment(acumuladoTotalRNew),
                });
            });

        } else {
            console.log("No hay posiciones");
            return [false];
        }


        return [true];
    } catch (error) {
        console.log(error);
        return [false];
    }
}

//USUARIOS.VUE
export async function agregaPosicionArrayRentabilidadesMeses(json) {
    try {
        //console.log(json);
        const fechaActual = new Date();
        const id = json.datos.id;
        const porcentajeActual = json.datos.porcentaje;
        let acumuladoTotalRNew = 0;
        let acumuladoInvestment = 0;

        const porcentajeGanancia = porcentajeActual / 100; // Convertimos el porcentaje a decimal
        const rentabilidad = porcentajeGanancia * json.datos.investment;

        let valorNumericoPG = parseFloat(rentabilidad.toFixed(2));

        const ref = doc(db, "solicitudes", id);

        // Obtén el array existente
        const rentabilidadesMensuales = json.datos.rentabilidadMensual || [];

        // Supongamos que quieres agregar las rentabilidades de los meses faltantes en `json.mesesFaltantes`
        const nuevasPosiciones = json.mesesFaltantes;

        // Recorre el array y agrega las propiedades a cada elemento
        const rentabilidadesActualizadas = nuevasPosiciones.map((mes) => {

            let tipoRendimiento = "";

            if (mes.esTrimestre) {
                tipoRendimiento = "Recomposición de Capital";
                acumuladoInvestment += valorNumericoPG;
            } else {
                tipoRendimiento = "Rendimiento";
                acumuladoTotalRNew += valorNumericoPG;
            }

            return {
                ...mes,
                estadoAdmin: true,
                fechaRegistro: fechaActual,
                pago: false,
                porcentajeGanancia: porcentajeActual,
                rentabilidad,
                rentabilidadTotal: valorNumericoPG,
                tipoMovimiento: tipoRendimiento,
            };
        });

        //console.log(acumuladoTotalRNew);


        // Agrega las nuevas posiciones al array existente
        const actualizacion = {
            "rentabilidadMensual": arrayUnion(...rentabilidadesMensuales, ...rentabilidadesActualizadas),
            "rentabilidadTotal": increment(acumuladoTotalRNew),
        };

        await updateDoc(ref, actualizacion);

        if (acumuladoInvestment > 0) {
            await updateInversion(acumuladoInvestment, id);
        }



        return [true];
    } catch (error) {
        console.log("Error:" + error)
    }
}

async function updateInversion(llegaMonto, idDocumento) {
    let rentabilidadActual = 0;
    const docRef = doc(db, "solicitudes", idDocumento);

    // Obtener los datos del documento
    const documentSnapshot = await getDoc(docRef);

    if (documentSnapshot.exists()) {
        // El documento existe, puedes acceder a sus datos
        const data = documentSnapshot.data();
        //console.log(data.rentabilidadTotal);

        rentabilidadActual = data.rentabilidadTotal;
        llegaMonto += rentabilidadActual;

    } else {
        // El documento no existe
        //console.log("El documento no existe.");
    }

    //Se debe consultar, para pasar todo lo que hay en rendimientos
    //y dejarlo en $0 y pasarlo a Capital

    await updateDoc(docRef, {
        investment: increment(llegaMonto),
        rentabilidadTotal: 0,
    });
}

export async function consultaUsuarioInversionFB() {
    try {
        let inversiones = [];
        const q = query(collection(db, "solicitudes"));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {

            //console.log(doc.id, " => ", doc.data());

            inversiones.push({ datos: { ...doc.data(), id: doc.id } });

        });

        // Ordenar inversiones por fechaRegistro en orden descendente
        inversiones.sort((a, b) => b.datos.fechaRegistro - a.datos.fechaRegistro);

        return [true, inversiones];

    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error("Error en el inicio de sesión:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}


export async function consultaUsuarioFB() {
    try {
        let usuarios = [];
        const q = query(collection(db, "usuarios"));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {

            //console.log(doc.id, " => ", doc.data());

            usuarios.push({ datos: { ...doc.data(), id: doc.id } });

        });

        // Ordenar usuarios por fechaRegistro en orden descendente
        usuarios.sort((a, b) => b.datos.fechaRegistro - a.datos.fechaRegistro);

        return [true, usuarios];

    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error("Error en el inicio de sesión:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}

export async function consultaUsuInvFB(json) {
    try {
        const { correoUser } = json;

        let inversiones = [];

        // Consulta las solicitudes filtradas por el usuario y ordenadas por fechaRegistro
        const q = query(
            collection(db, "solicitudes"),
            where("usuario", "==", correoUser),
            orderBy("fechaRegistro", "desc")
        );

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            inversiones.push({ datos: { ...doc.data(), id: doc.id } });
        });

        if (inversiones.length > 0) {
            return [true, inversiones];
        } else {
            return [false];
        }



    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        return [false, errorCode, errorMessage];
    }
}

//La trabaja Usuarios.vue para calcular 
export async function consultaUsuInvTrueFB(json) {
    try {

        const { correoUser } = json;

        let inversiones = [];

        // Consulta las solicitudes filtradas por el usuario y ordenadas por fechaRegistro
        const q = query(
            collection(db, "solicitudes"),
            where("usuario", "==", correoUser),
            orderBy("fechaRegistro", "desc")
        );

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            inversiones.push({ datos: { ...data } });
        });

        return [true, inversiones];

    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        return [false, errorCode, errorMessage];
    }
}


export async function actualizaInvestment(json, totalInversion) {
    try {
        const { correoUser } = json;

        // Realiza la consulta para encontrar los documentos que coinciden con el usuario
        const q = query(
            collection(db, "solicitudes"),
            where("usuario", "==", correoUser)
        );

        // Obtiene los documentos que cumplen con la consulta
        const querySnapshot = await getDocs(q);

        // Elimina el símbolo '$' y convierte la cadena en un número
        totalInversion = parseFloat(totalInversion.replace('$', '').replace(',', ''));

        // Itera sobre los documentos y actualiza la propiedad 'investment'
        querySnapshot.forEach(async (doc) => {
            const solicitudDocRef = doc.ref;
            //const solicitudData = doc.data();

            // Realiza la actualización de la propiedad 'investment' con el número convertido
            await updateDoc(solicitudDocRef, {
                investment: totalInversion
            });
        });
    } catch (error) {
        console.error(error);
    }
}


export async function actualizaInversionUsuarioBasico(json) {
    try {
        //console.log(json);
        //solo para temas numéricos

        let nuevoValor = json.valor;
        const correoUsuario = json.correoUser;
        const clave = json.clave;

        const qUpDate = query(
            collection(db, "solicitudes"),
            where("usuario", "==", correoUsuario)
        );

         // Obtiene los documentos que cumplen con la consulta
         const querySnapshotqUpDate = await getDocs(qUpDate);

         // Itera sobre los documentos y actualiza la propiedad 'investment'
         querySnapshotqUpDate.forEach(async (doc) => {
            const solicitudDocRef = doc.ref;
            //const solicitudData = doc.data();

            // Realiza la actualización de la propiedad 'investment' con el número convertido
            await updateDoc(solicitudDocRef, {
                [clave]: nuevoValor,
            });
        });


        return [true];

    } catch (error) {
        console.log(error);
    }
}