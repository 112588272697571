<template>
  <!--is-outlined -->
  <button
    class="button is-primary is-rounded  floating-button-whatsapp"
    style="width: 3rem; height: 3rem;  margin-bottom: 70px;"
    @click="link"
  >
    <span class="icon is-large">
      <i class="fab fa-whatsapp fa-2x"></i>
    </span>
  </button>
</template>

<script>
export default {
  name: "WhatsappBotton",

  methods: {
    link() {
      window.open("https://wa.me/573212696856", "_blank");
    },
  },
};
</script>