import { auth, reauthenticateWithCredential, updatePassword, db, EmailAuthProvider, collection, query, where, getDocs, updateDoc, doc, ref, storage, uploadBytesResumable, getDownloadURL, list } from "@/utils/firebaseConfig.js";

export async function consultaUsuarioFB(usuario) {
    try {
        let datosUsuario = [];
        const q = query(collection(db, "usuarios"), where("email", "==", usuario));

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {

            //console.log(doc.id, " => ", doc.data());

            datosUsuario.push({ id: doc.id, datos: doc.data() });

            return;
        });


        return [true, datosUsuario];

    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error("Error en el inicio de sesión:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}


export async function updateUsuarioFB(json) {
    try {
        //console.log(json);
        const id = json.id;
        const archivo = json.file;

        if (archivo != null) {
            const urlFile = await updateDocumento(archivo, id);
            json.file = urlFile;

            const docRef = doc(db, "usuarios", id);

            delete json.id;
            await updateDoc(docRef, json);

            return [true];
        } else {

            const docRef = doc(db, "usuarios", id);

            delete json.id;
            delete json.file;
            await updateDoc(docRef, json);

            return [true];

        }

    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error en la actualización: ", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}

async function updateDocumento(archivo, id) {
    try {
        // 1. Crear una referencia a un lugar específico en Firebase Storage.
        const storageRef = ref(storage, 'cuentas/' + id + '/cuenta');

        // 2. Usar la función put() para cargar el archivo.
        // Cargar el archivo
        const snapshot = await uploadBytesResumable(storageRef, archivo);

        // Obtener el URL del archivo
        const urlFile = await getDownloadURL(snapshot.ref);
        //console.log('File available at', urlFile);

        return urlFile;

    } catch (error) {
        return false;
    }
}

export async function updateUsuarioPass(json) {
    try {
        //console.log("Entra a ..... updateUsuarioPass");

        const newPassword = json.cambio;
        const user = auth.currentUser;

        if (!user) {
            console.error("No hay ningún usuario autenticado.");
            return false;
        }

        let currentPassword = json.actual;
        const email = user.email;
        const credential = EmailAuthProvider.credential(email, currentPassword);

        // Vuelve a autenticar al usuario
        await reauthenticateWithCredential(user, credential);

        // El usuario se ha reautenticado. Ahora puedes cambiar la contraseña
        await updatePassword(user, newPassword);

        console.log("Se ha modificado la contraseña");
        return true;
    } catch (error) {
        console.error("Error durante la reautenticación o el cambio de contraseña:", error);
        return false;
    }
}

export async function saveImageToFolder(userId, imageFile) {

    //const storageRef = ref(storage, `photo/${userId}/${imageFile.name}`);
    const imageName = 'avatar'; // Nombre fijo que deseas asignar
    const imageExtension = imageFile.name.split('.').pop(); // Obtener la extensión del archivo original

    const storageRef = ref(storage, `photo/${userId}/${imageName}.${imageExtension}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    // Escuchar eventos de estado, por ejemplo, progreso de carga
    /*uploadTask.on("state_changed", (snapshot) => {
      // Puedes manejar eventos de progreso aquí si es necesario
      console.log("Upload");
    });*/

    // Obtener la URL de descarga después de cargar exitosamente
    try {
        await uploadTask;
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    } catch (error) {
        console.error("Error al cargar la imagen:", error);
        throw error;
    }
}


export async function getImageFromFolder(userId) {
    // Referencia a la carpeta en Storage
    const storageRef = ref(storage, `photo/${userId}`);

    try {
        // Obtener la lista de elementos en la carpeta
        const result = await list(storageRef);

        // Verificar si hay algún elemento en la carpeta
        if (result.items.length > 0) {
            // Obtener la referencia al primer elemento (archivo) de la carpeta
            const firstItemRef = result.items[0];

            // Obtener la URL de descarga del archivo
            const downloadURL = await getDownloadURL(firstItemRef);

            // Devolver la URL de descarga
            return downloadURL;
        } else {
            // No hay archivos en la carpeta
            //console.warn("No hay archivos en la carpeta.");
            return null;
        }
    } catch (error) {
        // Manejar errores
        console.error("Error al obtener la imagen de la carpeta:", error);
        throw error;
    }
}

