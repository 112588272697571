<template>
  <div class="column is-three-five is-offset-one-five is-mobile">
    <div class="column is-three-fifths is-offset-one-fifth is-mobile">
      <bar />
      <div class="box rounded-box mt-2">
        <h1 class="title bienvenido-text">Administración</h1>
      </div>
    </div>

    <div class="column is-three-fifths is-offset-one-fifth is-mobile">
      <miga />

      <!-- USUARIOS E INVERSIONES -->
      <div class="box">
        <!-- Titulo y Botón de refrescar tabla -->
        <div class="column is-mobile is-justify-content-center">
          <label class="label">Listado Inversiones</label>
          <div class="is-flex is-justify-content-flex-end mt-n10">
            <v-btn
              icon="fas fa-arrows-rotate"
              size="x-small"
              @click="refreshData"
            ></v-btn>
          </div>
        </div>

        <!-- Carga y Muestra de Datos -->
        <!-- is-mobile is-justify-content-center -->
        <div class="column">
          <div v-if="myData === null">
            <progress class="progress is-small is-warning" max="100"></progress>
            Cargando Listado
          </div>

          <div v-else>
            <table class="custom-width-class" v-if="myData == 0">
              <p>...</p>
            </table>
            <!-- custom-width-class -->
            <table class="column" v-else>
              <VuetifyTableUsers :juego="myData" :refreshData="refreshData" />
            </table>
          </div>
        </div>

        {{ procesandoText }} {{ recorridoUno.length }}
      </div>

      <!-- Botón flotante en la parte superior derecha -->
      <whatsapp />
    </div>
  </div>
</template>

<script>
import VuetifyTableUsers from "@/components/Dashboard/herramientas/TablaAd.vue";
import Whatsapp from "@/components/Whatsapp.vue";
import Bar from "@/components/Dashboard/bar_01.vue";
import Miga from "@/components/Dashboard/Miga.vue";
import {
  consultaUsuarioFB,
  consultaUsuInvTrueFB,
  //agregaArrayRentabilidades,
  agregaPosicionArrayRentabilidades,
  agregaPosicionArrayRentabilidadesMeses,
} from "@/config/inversiones/indexAd";

export default {
  name: "DashboardUsuarios",
  components: { Whatsapp, Bar, Miga, VuetifyTableUsers },
  data() {
    return {
      myData: null,
      myDataTran: [],
      myDataTran2: [],
      processing: false,
      recorridoUno: [],
      procesandoText: "",
    };
  },

  created() {
    this.inicializar();
  },

  mounted() {
    //this.dialogRefs["dialog-" + id].open();
  },

  methods: {
    async refreshData() {
      this.myData = [];
      this.recorridoUno = [];
      // Lógica para volver a cargar myData
      await this.inicializar();
    },

    handleUpdateJuegos(nuevosJuegos) {
      this.tuArregloDeJuegos = nuevosJuegos;
      // También puedes realizar otras acciones aquí si es necesario
    },

    async inicializar() {
      //const result = await consultaUsuarioInversionFB();
      const resultUser = await consultaUsuarioFB();
      //console.log(result);

      if (resultUser[0]) {
        //console.log(result[1]);
        //this.myDataTran = result[1];
        this.myData = resultUser[1];
        await this.procesando();
      } else {
        this.myData = [];
      }
    },

    async procesando() {
      //console.log(this.myData);

      const resultados = [];

      for (const user of this.myData) {
        const json = {
          idUser: user.datos.id,
          correoUser: user.datos.email,
        };

        this.procesandoText = json.correoUser;

        const [exitoso, inversiones] = await consultaUsuInvTrueFB(json);

        //para cuando es el primer registro en inversiones
        if (exitoso && inversiones.length > 0) {
          //console.log("Si tiene inversiones");
          const fechaActual = new Date(); // Obtén la fecha actual
          //const idDoc = inversiones[0].datos.id;
          const fechaPrimerInversion =
            inversiones[0].datos.rentabilidadMensual[0].fechaRegistro; //timestamp

          //RECORRER LAS INVERSIONES ACTUALES
          inversiones.forEach(async (inversion) => {
            //VERSION V 3.0
            //CONSIDERO MIRAR SI SOLO TIENE LA PRIMERA INVERSIÓN EN TRUE, POR LA RAZON
            // QUE NO SE DEBE HACER OTRO ANÁLISIS.
            //console.log(inversion.datos.fuente);
            if (
              inversion.datos.fuente === "Adicionar Capital" ||
              inversion.datos.fuente === "Recomposición de Capital" ||
              inversion.datos.fuente === "Inversión"
            ) {
              //1. --------------------- PASO 1 ---------------------
              // Calcula la rentabilidad mensual y guárdala en la inversión
              //trimestre //calcularMesActual
              const resultCMF = await this.calcularMesesFaltantes(
                inversion.datos.rentabilidadMensual,
                fechaActual,
                fechaPrimerInversion
              );

              //console.log(resultCMF);

              //console.log(resultCMF.proceso);

              //proceso = 0  es porque es un solo registro
              //proceso = 1 es un conjunto de meses
              if (resultCMF.proceso === 0) {
                if (resultCMF.mesesFaltantes.length > 0) {
                  ///  ---- FALTAN MESES  ---  ///
                  /*console.log(
                    `Faltan calcular los siguientes meses: ${resultCMF.mesesFaltantes.join(
                      ", "
                    )}`
                  );*/

                  const jsonIn = {
                    datos: inversion.datos,
                    mesesFaltantes: resultCMF.mesesFaltantes,
                  };

                  //console.log(jsonIn);

                  if (resultCMF.mesesFaltantes.length > 0) {
                    await agregaPosicionArrayRentabilidades(jsonIn);
                  } else {
                    //console.log("No hay meses!");
                  }

                  //console.log(resultAddMes);
                } else {
                  /*console.log(
                    "No es necesario calcular el mes actual ni hay meses faltantes."
                  );*/
                }
              } else if (resultCMF.proceso === 1) {
                //console.log("Son mas de un mes, o al menos no es el primero");
                //console.log(resultCMF.faltantesMeses);
                const jsonIn2 = {
                  datos: inversion.datos,
                  mesesFaltantes: resultCMF.faltantesMeses,
                };

                //const resultAPARM =
                  await agregaPosicionArrayRentabilidadesMeses(jsonIn2);

                //console.log(resultAPARM);
              }

              //2. --------------------- PASO 2
              // Calcula la rentabilidad total y guárdala en la inversión
              /*console.log(inversion);*/
              inversion.datos.rentabilidadTotal =
                this.calcularRentabilidadTotal(
                  inversion.datos.rentabilidadMensual
                );
            }

            // TERMINA IF DE "Adicionar Capital" o  "Inversión"
          });

          // TERMINA EL FILTRO
          resultados.push({ usuario: json.correoUser, inversiones });
        } else if (exitoso && inversiones.length <= 0) {
          //console.log("No tiene inversiones");
        } else if (!exitoso) {
          console.error(
            `Error al consultar para el usuario ${user.datos.email}`
          );
        }
      } //Termina FOR

      //console.log(resultados);
      this.recorridoUno = resultados;
      this.procesandoText = "Finalizó Análisis, total usuario: ";
    },

    calcularRentabilidadTotal(resultados) {
      let rentabilidadTotalAcumulativa = 0;
      for (const resultado of resultados) {
        rentabilidadTotalAcumulativa += parseFloat(resultado.rentabilidadTotal);
      }
      return rentabilidadTotalAcumulativa.toFixed(2);
    },

    //Calcula los meses faltantes
    async calcularMesesFaltantes(
      rentabilidadMensual,
      fechaActual,
      fechaPrimerInversion
    ) {
      if (!rentabilidadMensual || rentabilidadMensual.length === 0) {
        // Si no hay datos en rentabilidadMensual, retornar valores predeterminados.
        return {
          mesesFaltantes: [],
        };
      }

      if (
        rentabilidadMensual.length === 1 &&
        rentabilidadMensual[0].tipoMovimiento === "Inversion" &&
        rentabilidadMensual[0].estadoAdmin === true
      ) {
        const fechaInversion = new Date(
          rentabilidadMensual[0].fechaRegistro.seconds * 1000
        );

        if (
          fechaInversion.getMonth() !== fechaActual.getMonth() &&
          fechaActual.getDate() > 1
        ) {
          // Verificar si el mes de inversión es diferente al mes actual y si el día actual es posterior al primero del mes
          const ultimoDiaMesInversion = new Date(fechaInversion);
          ultimoDiaMesInversion.setMonth(ultimoDiaMesInversion.getMonth() + 1);
          ultimoDiaMesInversion.setDate(0); // Obtener el último día del mes de la inversión
          const tiempoPasado = ultimoDiaMesInversion - fechaInversion;
          const diasProrrateados = tiempoPasado / (1000 * 60 * 60 * 24); // Calcular los días prorrateados
          const mesCalculado = fechaInversion.toLocaleString("es-ES", {
            month: "long",
          }); // Establecer el mes calculado como el mes de la inversión

          /*const esTrimestre = this.verificarSiEsTrimestre(
            fechaInversion,
            fechaPrimerInversion
          );*/

          return {
            mesesFaltantes: [
              {
                mesCalculado: mesCalculado, // Mes calculado
                trimestre: false, //esTrimestre, // Indicar si es un trimestre, lo quité porque se entiende que el primer mes no es posible que sea trimestre
                dias: Math.floor(diasProrrateados), // Días prorrateados redondeados
              },
            ],
            proceso: 0,
          };
        } else {
          //console.log("No ha cumplido la fecha.");
          return {
            mesesFaltantes: [],
            proceso: 0,
          };
        }
      } else {
        // Implementar el resto de los requisitos aquí
        // (Requisitos 2, 3, 4, 5, 6, 7, 8)
        // ...
        //console.log("Ingreso cuano ya hay rendimientos...");

        let rendimientoEntry = null;

        for (let i = rentabilidadMensual.length - 1; i >= 0; i--) {
          if (
            rentabilidadMensual[i].tipoMovimiento === "Rendimiento" ||
            (rentabilidadMensual[i].tipoMovimiento ===
              "Recomposición de Capital" &&
              rentabilidadMensual[i].estadoAdmin === true)
          ) {
            rendimientoEntry = rentabilidadMensual[i];
            break;
          }
        }

        //console.log(rendimientoEntry);

        if (rendimientoEntry) {
          // Si se encontró una entrada de 'Rendimiento'
          // Realizar cálculos basados en la entrada de 'Rendimiento'
          // (Requisitos 5, 6, 7, 8)
          // ...
          const fechaUltimoRendimiento = new Date(
            rendimientoEntry.fechaRegistro.seconds * 1000
          );

          const mesesFaltantes = this.calcularMesesEntreFechas(
            fechaUltimoRendimiento,
            fechaActual
          );

          //console.log(mesesFaltantes);

          const esTrimestre = this.verificarSiEsTrimestre(
            mesesFaltantes,
            fechaPrimerInversion
          );

          //console.log(esTrimestre);

          return {
            mesesFaltantes: [],
            faltantesMeses: esTrimestre,
            proceso: 1,
          };
        } else {
          // Implementar lo que debe hacerse en caso de no encontrar 'Rendimiento'
          // (puede ser un mensaje de error, por ejemplo)
          //console.log("No hay nada procesos pendientes.", rendimientoEntry);
          return {
            mesesFaltantes: [],
            faltantesMeses: [],
            proceso: 1,
          };
        }
      } //Termina el else prinpcial
    },

    calcularMesesEntreFechas(fechaInicio, fechaFin) {
      const meses = [];
      let fechaActual = new Date(fechaInicio);
      const fechaHoy = new Date(); // Obtener la fecha actual

      while (fechaActual < fechaFin) {
        if (
          fechaActual.getMonth() !== fechaHoy.getMonth() ||
          fechaActual.getFullYear() !== fechaHoy.getFullYear()
        ) {
          // Agregar el mes solo si es diferente al mes actual
          meses.push(fechaActual.toLocaleString("es-ES", { month: "long" }));
        }
        fechaActual.setMonth(fechaActual.getMonth() + 1);
      }

      return meses;
    },

    verificarSiEsTrimestre(mesesFaltantes, fechaPrimerInversion) {
      if (typeof fechaPrimerInversion === "number") {
        fechaPrimerInversion = new Date(fechaPrimerInversion * 1000);
      }

      const result = [];
      const fechaFPI = new Date(fechaPrimerInversion.seconds * 1000); // Multiplicamos por 1000 para convertir los segundos en milisegundos
      const mesInicio = fechaFPI.getMonth(); // Mes de fechaPrimerInversion
      //const añoInicio = fechaFPI.getFullYear(); // Año de fechaPrimerInversion

      for (let i = 0; i < mesesFaltantes.length; i++) {
        const mes = mesesFaltantes[i];
        const numeroDelMes = this.obtenerNumeroDeMes(mes);
        //const añoActual = fechaFPI.getFullYear();
        let esTrimestre = false;

        // Comprobar si el mes actual es el inicio de un trimestre
        if ((mesInicio - (numeroDelMes + 1) + 12) % 3 === 0) {
          esTrimestre = true;
        }

        const dias = this.obtenerDiasEnMes(mes, fechaFPI.getFullYear()); //diasMeses

        result.push({ mes, esTrimestre, dias });

        fechaFPI.setMonth(fechaFPI.getMonth() + 1);
      }

      return result;
    },

    obtenerDiasEnMes(mes, año) {
      const fecha = new Date(año, this.obtenerNumeroMes(mes), 1);
      fecha.setMonth(fecha.getMonth() + 1);
      fecha.setDate(fecha.getDate() - 1);
      return fecha.getDate();
    },

    obtenerNumeroMes(mes) {
      const meses = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];
      return meses.indexOf(mes.toLowerCase());
    },

    obtenerNumeroDeMes(nombreMes) {
      const meses = {
        enero: 0,
        febrero: 1,
        marzo: 2,
        abril: 3,
        mayo: 4,
        junio: 5,
        julio: 6,
        agosto: 7,
        septiembre: 8,
        octubre: 9,
        noviembre: 10,
        diciembre: 11,
      };

      // Convierte el nombre del mes a minúsculas y obtén su número correspondiente
      const nombreMesMin = nombreMes.toLowerCase();
      const numeroMes = meses[nombreMesMin];

      return numeroMes;
    },
  },
};
</script>

<style  scoped>
.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 0px; /* 10px Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #cfcfcf; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h1.bienvenido-text {
  color: #f8d07e;
  font-size: 24px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* Otros estilos según sea necesario */
}

.rounded-box p,
.rounded-box span {
  color: #f8d07e;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* Otros estilos según sea necesario */
}

.is-flex-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.table-row {
  flex-basis: calc(20% - 1em); /* Ajusta el porcentaje según sea necesario */
}

.boton-transacciones {
  border-radius: 100px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  color: #cfcfcf;
  border: 2px solid #cfcfcf; /* Agregar borde con color y grosor */
  display: inline-block; /* Hacer que el botón se comporte como un elemento en línea */
  text-align: center; /* Centrar el texto horizontalmente */
  text-decoration: none; /* Quitar el subrayado predeterminado de los enlaces */
}

/* Pseudo-clases para estilos de hover */
.boton-transacciones:hover {
  background: linear-gradient(to bottom, #e8e8e8, #bebebe);
  color: #ffffff;
}

.custom-width-class {
  width: 100%;
}
</style>