<template>
  <div class="column is-three-five is-offset-one-five is-mobile">
    <div class="column is-three-fifths is-offset-one-fifth is-mobile">
      <bar />
      <div class="block box rounded-box mt-2">
        <h1 class="title bienvenido-text">Mis Transacciones</h1>
      </div>
    </div>

    <div class="column is-three-fifths is-offset-one-fifth is-mobile">
      <miga />

      <!-- CAPITAL -->
      <div class="block box">
        <div class="column is-mobile is-justify-content-center">
          <!-- CAMPOS INICIALES -->
          <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile">
              <label>Capital</label>
              <input
                :value="formatCurrency(totalinversion)"
                class="input"
                type="text"
                placeholder="$0"
                disabled
              />
            </div>
            <div class="column is-full-mobile">
              <label>Rendimientos</label>
              <input
                :value="formatCurrency(rendimientosAcumulado)"
                class="input"
                type="text"
                placeholder="$0"
                disabled
              />
            </div>

            <div class="column is-full-mobile">
              <label>Periodo</label>
              <v-row>
                <v-col cols="12" md="6">
                  <input
                    class="input is-mobile"
                    type="date"
                    placeholder="Fecha de inicio"
                    disabled
                  />
                </v-col>
               
                <v-col cols="12" md="6">
                  <input
                    class="input is-mobile"
                    type="date"
                    placeholder="Fecha de fin"
                    disabled
                  />
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- SEGUNDA SECCIÓN-->
          <div class="columns">
            <div class="column is-flex is-align-items-center">
              <button
                class="button boton-transacciones"
                @click="realizarAccion('Adicionar Capital')"
                :disabled="bloqueMivimientoTra || hayInversiones"
              >
                <span class="icon is-small">
                  <i class="fas fa-plus"></i>
                </span>
              </button>
              <span style="margin-top: 0.5px" class="ml-2">
                Adicionar Capital
              </span>
            </div>

            <div class="column is-flex is-align-items-center">
              <button
                class="button boton-transacciones"
                @click="realizarAccion('Retirar Capital')"
                :disabled="bloqueMivimientoTra || hayInversiones"
              >
                <span class="icon is-small">
                  <i class="fas fa-minus"></i>
                </span>
              </button>
              <span style="margin-top: 0.5px" class="ml-2">
                Retirar Capital
              </span>
            </div>

            <div class="column is-flex is-align-items-center">
              <!-- || invVigente == null -->
              <button
                class="button boton-transacciones"
                @click="realizarAccion('Retirar Rendimientos')"
                :disabled="bloqueMivimientoTra || hayInversiones"
              >
                <span class="icon is-small">
                  <i class="fas fa-minus"></i>
                </span>
              </button>
              <span style="margin-top: 0.5px" class="ml-2">
                Retirar Rendimientos
              </span>
            </div>
          </div>

          <!-- Campo Retiros -->
          <div class="columns">
            <div class="column">
              <!--
                <div v-if="invVigente !== null">
                  <label
                    >Transacción # {{ invVigente.posicion }} / Digite en USD el
                    monto</label
                  >
                </div>
                <div v-else>
                  <label
                    >Seleccione una Transacción y Digite en USD el monto
                  </label>
                </div>

              -->

              <label>Digite en USD el monto </label>
              <!-- || invVigente == null  `Ingrese el monto en USD / Máximo: $` + totalDisponible-->
              <input
                class="input"
                type="number"
                :placeholder="`...`"
                v-model="monto"
                :disabled="bloqueMivimientoTra || hayInversiones"
              />
              <p class="help is-danger">{{ mensajeError }}</p>
            </div>
          </div>

          <!-- PROCESAR... LOADING.... -->
          <div v-if="procesandoInicio">
            <progress class="progress is-small is-warning" max="100"></progress>
            Procesando
          </div>

          <div v-else>
            <!--USUARIO NO TIENE INVERSIONES-->
            <div v-if="registros.length == 0">
              No tienes transacciones. Haz tu primera Inversión desde la
              Calculadora de Inversión.
            </div>

            <!-- TABLA USUARIO INVERSIONES -->
            <Table
              :datoTransaccion="registros"
              v-else-if="registros.length > 0"
              @some-event="actualizaIdInv"
            ></Table>
            <!--END USUARIO INVERSIONES-->
          </div>
        </div>
      </div>
    </div>

    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </div>
</template>

<script>
import Whatsapp from "@/components/Whatsapp.vue";
import Bar from "@/components/Dashboard/bar_01.vue";
import Miga from "@/components/Dashboard/Miga.vue";
import { useStore } from "vuex";
import {
  consultaUsuarioInversionFB,
  updateInversionFB,
  solicitudesinversorFB,
} from "@/config/inversiones/index";
import Table from "../herramientas/Tabla.vue";

export default {
  name: "DashboardTransacciones",

  components: { Whatsapp, Bar, Miga, Table },

  data() {
    return {
      rol: "inversor", //adm - inversor
      login: null,
      datosUsuario: null,
      verificaEmail: null,
      options: [
        { label: "Rendimiento", value: "rendimiento" },
        { label: "Capital y Rendimientos", value: "capital-rendimientos" },
        { label: "Capital parcial", value: "capital-parcial" },
      ],
      selectedOption: null,
      registros: [],

      sendSolicitud: false,
      textoProceso: "",

      procesando: true,
      procesandoInicio: false,

      totalinversion: 0,
      rendimientosAcumulado: 0,
      acumuladoArray: [],

      monto: "",
      mensajeError: "", // Variable para almacenar el mensaje de error
      bloqueMivimientoTra: false,

      invVigente: [],
      totalDisponible: 0,
      idInversionUnico: "",
      hayInversiones: false,
    };
  },

  mounted() {
    this.inicialziar();
  },

  computed: {
    // Propiedad computada que determina si el botón debe estar habilitado
    isButtonEnabled() {
      return this.inver.selectedOption && this.inver.retiroAmount >= 2;
    },
  },

  methods: {
    async inicialziar() {
      this.procesandoInicio = true;
      const store = useStore();

      /*console.log("Estado de isLoggedIn:", store.state.isLoggedIn);*/
      //console.log("Datos de usuario:", store.state.userData);

      this.login = store.state.isLoggedIn;
      this.datosUsuario = store.state.userData;
      this.verificaEmail = this.datosUsuario.emailVerified;

      //console.log("Datos de usuario:", this.datosUsuario.email);

      // FALTA ROL por Ajustar...
      if (!this.datosUsuario.email) {
        this.$router.push({ name: "Login" });
      }

      const result = await consultaUsuarioInversionFB(this.datosUsuario.email);
      //console.log(result);

      if (result[1].length > 0) {
        //console.log("Hay Inversiones");
        this.procesandoInicio = false;
        this.registros = result[1];

        this.totalinversion = result[1][0].datos.investment; // total inversion Actual

        this.rendimientosAcumulado = result[1][0].datos.rentabilidadTotal; // total inversion Actual

        //console.log(this.registros);

        //console.log('Existe');
        this.hayInversiones = false; //Para habilitar los botones
      } else {
        //console.log("No hay");

        this.procesandoInicio = false;
        this.registros = result[1];
        const registrosArray = this.registros.slice(); // Convierte el Proxy Array en un array normal

        //console.log('No Existe');
        this.hayInversiones = true; //Para habilitar los botones

        //console.log(registrosArray);

        //console.log(this.registros);

        // Dentro del método donde recibes los registros
        this.totalinversion = registrosArray
          .filter((registro) => registro.datos.estadoAdmin)
          .reduce((total, registro) => total + registro.datos.investment, 0);

        //console.log("Total inversion");
        //console.log(this.totalinversion);

        // Inicializa el array para almacenar los rendimientos acumulados
        this.acumuladoArray = [];

        // Dentro del método donde recibes los registros
        //const hoy = new Date(); // Fecha actual

        /*this.rendimientosAcumulado = registrosArray
          .filter((registro) => registro.datos.estadoAdmin)
          .reduce((acumulado, registro) => {
            const fechaRegistro = new Date(
              registro.datos.fechaRegistro.seconds * 1000
            ); // Convertir timestamp a Date
            const diasDiferencia = Math.floor(
              (hoy - fechaRegistro) / (1000 * 60 * 60 * 24)
            );

            if (diasDiferencia >= 31) {
              const porcentaje = registro.datos.porcentaje || 0; // Asegurar que porcentaje sea un número válido
              const investment = registro.datos.investment || 0; // Asegurar que investment sea un número válido

              let rendimientoAcumulado = 0;

              // Agrega múltiples registros para cada rendimiento acumulado
              for (let i = 0; i < 3; i++) {
                // Calcula la fecha de cierre sumando i meses a la fecha actual
                const fechaCierre = new Date();
                fechaCierre.setMonth(fechaCierre.getMonth() + i);

                // Formatea la fecha de cierre según el formato deseado
                const fechaCierreFormateada =
                  fechaCierre.toLocaleDateString("es-CO");

                // Calcula el rendimiento diario (asumiendo 30 días en un mes)
                const rendimientoDiario =
                  (investment * (porcentaje / 100)) / 30;

                // Acumula el rendimiento diario
                rendimientoAcumulado += rendimientoDiario;

                // Agrega el rendimiento acumulado y su fecha de cierre al array
                this.acumuladoArray.push({
                  fechaCierre: fechaCierreFormateada,
                  rendimientoAcumulado: rendimientoAcumulado,
                  investment: investment,
                  porcentaje: porcentaje,
                  detalleAdicional: `Detalle ${i + 1}`, // Puedes ajustar esto según sea necesario
                });
              }

              return acumulado + rendimientoAcumulado;
            } else {
              return acumulado;
            }
          }, 0);*/
      } //END
    },

    //Para saber que transacción afectar
    actualizaIdInv(a) {
      //console.log(a);
      if (a == null) {
        this.totalDisponible = 0;
        this.invVigente = null;
        this.monto = "";
      } else {
        this.totalDisponible =
          a.investment + (a.rentabilidadTotal ? a.rentabilidadTotal : 0);
        this.invVigente = a;
      }
    },

    async consulta() {
      this.registros = [];

      const result = await consultaUsuarioInversionFB(this.datosUsuario.email);
      this.registros = result[1];

      // En su lugar, usa métodos reactivos para modificar el array existente
      this.registros.splice(0, this.registros.length, ...result[1]);
    },

    async generarSolicitud(inver) {
      //console.log(inver);
      if (!inver.selectedOption || inver.selectedOption.trim().length <= 1) {
        // El valor llega vacío o con 1 o menos caracteres
        return;
      }

      this.textoProceso = "Procesando solicitud";
      this.procesando = false;

      // Ocultar los otros divs
      this.registros.forEach((registro) => {
        // console.log(registro);
        if (registro.id !== inver.id) {
          registro.mostrar = false;
        }
      });

      const result = await updateInversionFB(inver);

      //console.log(result);

      if (result[0]) {
        this.textoProceso =
          "Solicitud exitosa, espere confirmación del administrdor.";

        setTimeout(() => {
          this.textoProceso = "";
          this.consulta();
          this.procesando = true;
          //parentCard.style.display = "block"; // o "flex", "grid", etc.
        }, 2000);
      } else {
        this.textoProceso =
          "No se pudo procesar la solicitud intente de nuevo o consulte al administrador.";

        setTimeout(() => {
          this.textoProceso = "";

          this.procesando = true;
          //parentCard.style.display = "block"; // o "flex", "grid", etc.
        }, 2000);
      }
    },

    realizarAccion(accion) {
      // Convierte this.monto a cadena antes de llamar a trim()
      if (String(this.monto).trim() === "") {
        this.mensajeError = "Debe ingresar un valor.";
      } else {
        this.bloqueMivimientoTra = true;
        // Ejecutar la función correspondiente (puedes implementar esto más adelante)
        this.ejecutarAccion(accion);

        // Limpiar el mensaje de error si existe
        this.mensajeError = "";
      }
    },

    async ejecutarAccion(accion) {
      // Aquí puedes realizar la lógica específica para cada botón
      //console.log(`Ejecutando acción: ${accion}`);
      //console.log(`Monto:${this.monto}`);

      this.procesando = true;
      let montoLlega = this.monto;

      if (accion === "Retirar Rendimientos" || accion === "Retirar Capital") {
        // Convierte el monto en un número negativo si la acción es "Retirar Capital"
        montoLlega = -montoLlega;
      }

      //console.log(this.registros);
      this.idInversionUnico = this.registros[0].id;

      let json = {
        //months: 0,
        dias: 0,
        //investment: ,
        usuario: this.datosUsuario.email,
        rentabilidadTotal: montoLlega,
        pago: false,
        porcentajeGanancia: 0,
        //porcentaje: 0,
        //gM: 0,
        //gT: 0,
        tipoMovimiento: accion,
        descripcion: "Usuario",
        id: this.idInversionUnico, //Inversión a afectar
      };

      //console.log(json);

      const resultRIFB = await solicitudesinversorFB(json); //registroInversionFB

      if (resultRIFB[0]) {
        //Registro exitoso
        this.textoProceso = "Solicitud Exitosa";
        this.consulta();

        setTimeout(() => {
          this.monto = "";
          this.bloqueMivimientoTra = false;
          this.textoProceso = "";
          this.procesando = false;
        }, 3000);
      } else {
        //Error
        this.textoProceso = "Error 301, consulte al Administrator";
        setTimeout(() => {
          this.monto = "";
          this.bloqueMivimientoTra = false;
          this.textoProceso = "";
          this.procesando = false;
        }, 2000);
      }
    },

    seleccionarOpcion(inver, option) {
      inver.selectedOption = option.value;
    },

    convertirTimestampAFechaHora(timestamp) {
      const fecha = new Date(timestamp.seconds * 1000); // Multiplicamos por 1000 para convertir segundos a milisegundos
      return fecha.toLocaleString(); // Retorna la fecha en formato legible de acuerdo a la configuración regional
    },

    formatFirebaseTimestamp(timestamp) {
      // Convierte los segundos a milisegundos
      const milliseconds = timestamp.seconds * 1000;

      // Crea una nueva instancia de Date con los milisegundos
      const date = new Date(milliseconds);

      // Obtiene los componentes de la fecha
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      // Formatea el mes/año
      const formattedDate = `${month}/${year}`;

      return formattedDate;
    },

    formatCurrency(value) {
      if (typeof value === "string") {
        // Si el valor es una cadena, intenta convertirla a número
        value = parseFloat(value);
      }

      if (typeof value === "number" && !isNaN(value)) {
        // Verificar si el número es menor que 1 y ajustar el formato
        if (value < 1) {
          return value.toLocaleString("en-EN", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        } else {
          return value.toLocaleString("en-EN", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      } else {
        // Si no se pudo convertir a un número válido, devolver el valor original
        return value;
      }
    },
  },
};
</script>

<style  scoped>
.table-header {
  background: linear-gradient(to bottom, #fff4e0, #ffdfa4);
  color: #ffffff; /* Color blanco para el texto */
  text-decoration-color: #cfcfcf;
}
.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.scrollable-content {
  max-height: 65vh; /* El 80% de la altura de la ventana gráfica */
  overflow-y: auto; /* Añade scroll vertical cuando sea necesario */
}

.card-pair {
  background-color: #e1e1e1; /* Color de fondo para las cards pares */
}
.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 0px; /* 10px Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #cfcfcf; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h1.bienvenido-text {
  color: #f8d07e;
  font-size: 24px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* Otros estilos según sea necesario */
}

.rounded-box p,
.rounded-box span {
  color: #f8d07e;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* Otros estilos según sea necesario */
}

.is-flex-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.table-row {
  flex-basis: calc(20% - 1em); /* Ajusta el porcentaje según sea necesario */
}

.boton-transacciones {
  border-radius: 100px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  color: #cfcfcf;
  border: 2px solid #cfcfcf; /* Agregar borde con color y grosor */
  display: inline-block; /* Hacer que el botón se comporte como un elemento en línea */
  text-align: center; /* Centrar el texto horizontalmente */
  text-decoration: none; /* Quitar el subrayado predeterminado de los enlaces */
}

/* Pseudo-clases para estilos de hover */
.boton-transacciones:hover {
  background: linear-gradient(to bottom, #e8e8e8, #bebebe);
  color: #ffffff;
}

.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 767px) {
  .input {
    width: 100%;
    max-width: 100%;
  }
}
</style>